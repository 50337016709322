<template>
  <div>
    <div class="container">
      <div class="idx-content">
        <!-- 面包屑导航 -->
        <div class="class-nav">
          当前位置： <router-link tag="a" to="/">首页 </router-link>> 平台通知
        </div>
        <div class="idx-course-list">
          <div class="idx-course-listbox">
            <div class="idx-course-tab">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <!-- <div class="idx-course-list-title">
                <span></span>平台课程列表2
              </div> -->
                <div class="idx-tab-list" v-for="item in list" :key="item.id">
                  <router-link :to="'/noticeDetail/?id=' + item.id">
                    <div class="idx-tab-list-1">
                      <div class="idx-tab-list-1Left">
                        <img :src="item.imageUrl || selectDefaultImg(item.id)" />
                      </div>
                      <div class="idx-tab-list-1Right">
                        <div class="idx-R-1">
                          <!-- <span class="idx-R1-s1">最新</span> -->
                          <span class="idx-R1-s2">{{ item.title }}</span>
                        </div>
                        <div class="idx-R-2" v-html="item.content">
                          {{ item.content }}
                        </div>
                        <div class="idx-R-3">
                          <span class="idx-R3-s1">{{ item.createTime }}</span>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <!-- 已经到底了哦 -->
                <div class="idx-title">已经到底了哦</div>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listNotice } from "@/api/bm/notice";
export default {
  data() {
    return {
      activeName: "first",
      list: [],
      loading: false,
      finished: false,
      queryForm: {
        pageSize: 5,
        pageNumber: 1,
      },
      wayOptions: [],
      bmStatusOptions: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      listNotice().then((res) => {
        console.log("请求通知列表成功");
        console.log(res);
        this.list = res.rows;
      });
    },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: auto;
  //   background: linear-gradient(
  //     #dbe7fc 1%,
  //     #ffffff 20%,
  //     #ffffff 20%,
  //     #ffffff 20%,
  //     #ffffff 39%
  //   );
  background: #f8f8f8;
  padding-top: 22px;
  .idx-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    // text-align: center;
    img {
      width: 1300px;
      height: 450px;
    }
    .idx-course-list {
      width: 1300px;
      height: auto;
      // background: #ffffff;
      margin-top: 40px;
      padding-bottom: 68px;
      .idx-tab-list {
        padding-bottom: 20px !important;
        margin-top: 0px !important;
        .idx-tab-list-1 {
          margin-top: 20px !important;
        }
      }
      .idx-course-listbox {
        margin-top: 0px;
        position: relative;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-start;
        // align-items: center;
        ::v-deep .el-tabs__nav-wrap {
          overflow: hidden;
          margin-bottom: -1px;
          position: relative;
          padding-left: 247px;
        }
        ::v-deep .el-tabs__item {
          padding: 0 20px;
          height: 40px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          line-height: 25px;
          display: inline-block;
          list-style: none;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2c2c2c;
          position: relative;
        }
        ::v-deep .el-tabs__item.is-active {
          color: #409eff;
        }
        ::v-deep .el-tabs__active-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          background-color: #409eff;
          z-index: 1;
          -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          list-style: none;
        }
        .idx-course-list-title {
          font-size: 23px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #28333c;
          position: absolute;
          top: 1px;

          span {
            width: 5px;
            height: 27px;
            background: #2979ea;
            border-radius: 3px;
            float: left;
            margin-right: 10px;
          }
          .idx-s2 {
            width: 1px;
            height: 21px;
            background: #cccccc;
            float: right;
            margin-left: 52px;
          }
        }
        .idx-course-tab {
          .idx-title {
            width: 100%;
            height: 30px;
            line-height: 30px;
            margin: 0 auto;
            color: #7f7f7f;
            text-align: center;
            margin-top: 90px;
          }
          .idx-tab-list {
            cursor: pointer;
            padding-bottom: 54px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            // .idx-title {
            //   width: 100%;
            //   height: 30px;
            //   line-height: 30px;
            //   margin: 0 auto;
            //   color: #000000;
            //   text-align: center;
            //   margin-top: 50px;
            // }
            .idx-tab-list-1 {
              // border: 1px solid teal;
              height: 180px;
              margin-top: 59px;
              position: relative;
              .idx-tab-list-1Left {
                width: 260px;
                float: left;
                margin-right: 35px;
                img {
                  width: 260px;
                  height: 175px;
                  opacity: 1;
                  border-radius: 12px 12px 6px 6px;
                }
              }
              .idx-tab-list-1Right {
                width: 1000px;
                float: left;
                .idx-R-1 {
                  font-size: 18px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #000000;
                  .idx-R1-s1 {
                    font-size: 14px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #1264eb;
                    border: 1px solid #1264eb;
                    padding: 0 5px;
                    margin-right: 10px;
                  }
                }
                ::v-deep .idx-R-2 {
                  p {
                    display: inline-block !important;
                    background-color: rgba(255, 255, 255, 0) !important;
                    span {
                      background-color: rgba(255, 255, 255, 0) !important;
                    }
                  }
                  width: 99%;
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #7f7f7f;
                  margin: 40px 0px;
                  line-height: 25px;
                  height: 52px;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2; /*要显示的行数*/
                }
                .idx-R-3 {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                  .idx-R3-s2 {
                    font-size: 26px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #003cff;
                    margin-left: 30px;
                  }
                }
                .idx-R-4 {
                  width: 98px;
                  height: 38px;
                  line-height: 38px;
                  text-align: center;
                  background: linear-gradient(
                    -54deg,
                    rgba(30, 112, 234, 0.89),
                    rgba(72, 140, 240, 0.89)
                  );
                  border-radius: 4px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #ffffff;
                  position: absolute;
                  top: 13px;
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
