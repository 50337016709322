import request from '@/utils/request'
// 查询通知列表列表2去掉权限
export function listNotice(query) {
    return request({
        url: '/bm/notice/front/list?status=1',
        method: 'get',
        params: query,
    })
}
// 查询通知列表列表2去掉权限
export function listNoticexq(id) {
    return request({
        url: '/bm/notice/wx/' + id,
        method: 'get',
        headers: { isToken: false }
    })
}
